.rdp-super-admin-component-no-results {
  text-align: center;
  padding-top: 50px;
}

.rdp-super-admin-component-no-results-icon {
  width: 200px;
  height: 200px;
  display: block;
  margin: auto;
  background-image: url("./icon.jpg");
  background-size: 100%;
}

.rdp-super-admin-component-no-results-message {
  color: #4b227f;
  font-weight: bold;
}