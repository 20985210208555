* {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: -moz-none;
  -o-user-select: none;
  user-select: none;
}

input:focus, textarea:focus {
  outline:none;
}

*:focus, *:active, * { outline:0 !important; -webkit-tap-highlight-color: transparent;}


@font-face {
  font-family: TitilliumWeb-Regular;
  src: url("./assets/fonts/TitilliumWeb-Regular.ttf");
}

body {
  margin: 0;
  font-family: TitilliumWeb-Regular;
}

.rdp-super-admin-breadcrumb {
  padding: 10px 20px;
  background: #ededed;
  border-bottom: 1px solid #dfdfdf;
}

.rdp-super-admin-main {
  padding: 20px;
}

.rdp-super-admin-content {
  position: absolute;
  overflow-x: hidden;
  overflow-y: auto;
  top: 109px;
  bottom: 0px;
  left: 0px;
  right: 0px;
}

.rdp-super-admin-tab-content {
  display: block;
}

.rdp-super-admin-root {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  overflow-x: hidden;
  overflow-y: auto;
}