.rdp-super-admin-migration-column {
  width: 50%;
  padding: 25px;
  position: absolute;
  top: 0px;
  bottom: 0px;
  overflow-x: hidden;
  overflow-y: auto;
}

.rdp-super-admin-migration-column.first {
  width: calc(50% + 1px);
  left: 0px;
  padding-right: 40px;
  border-right: 2px solid #dfdfdf;
}

.rdp-super-admin-migration-column.second {
  width: calc(50% - 1px);
  left: calc(50% + 1px);
  left: 50%;
  padding-left: 40px;
}

.rdp-super-admin-migration-icon-right {
  position: absolute;
  width: 48px;
  height: 48px;
  z-index: 999;
  left: 50%;
  margin-left: -24px;
  top: 50%;
  margin-top: -24px;
  border-radius: 100px;
  background-color: #FFFFFF;
  border: 2px solid #dfdfdf;
}

.rdp-super-admin-migration-icon-right:before {
  content: "";
  position: absolute;
  width: 36px;
  height: 36px;
  z-index: 999;
  left: 50%;
  margin-left: -18px;
  top: 50%;
  margin-top: -18px;
  border-radius: 100px;
  background-color: #8e36b7;
  background-image: url("./icon-right.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 24px;
}

.rdp-super-admin-migration-column-preloader {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.8);
}

.rdp-super-admin-migration-column-preloader .MuiCircularProgress-root {
  position: absolute;
  top: 50%;
  margin-top: -20px;
  left: 50%;
  margin-left: -20px;
}